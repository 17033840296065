// src/pages/register/activate.tsx

import React, { FC, useEffect, useState } from 'react';
import { Box, Spinner, Text, Heading } from '@components/Primitives';
import { useIntl, FormattedMessage as M } from 'gatsby-plugin-intl';
import { ContentPageLayout } from '@components/Layout';
import { activateUser } from '../../services/api'
import { useLocation } from '@reach/router';
import { PATHS } from '@constants';

const ActivatePage: FC = () => {
  const location = useLocation();
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code');
  
    // Function declaration outside the block
    async function activate() {
      if (code) {
        try {
          await activateUser(code as string);
          setLoading(false);
        } catch (err) {
          console.error('Activation error:', err);
          setError('pages.register.activateError');
          setLoading(false);
        }
      } else {
        setError('pages.register.invalidActivationCode');
        setLoading(false);
      }
    }

    activate();
  }, [location.search]);
  

  if (loading) {
    return (
      <ContentPageLayout twoColumnLayout>
        <Box pl="2" pr="2" textAlign="center">
          <Spinner color="mainBlue" />
        </Box>
      </ContentPageLayout>
    );
  }

  return (
    <ContentPageLayout twoColumnLayout>
      <Box pl="2" pr="2" textAlign="center">
        {error ? (
          <Text color="red">
            <M id={error} />
          </Text>
        ) : (
          <>
            <Heading level="3">
              <M id="pages.register.activateSuccessTitle" />
            </Heading>
            <Text mt="2">
              <M
                id="pages.register.activateSuccessMessage"
                values={{
                  link: (
                    <a href={PATHS.auth.login}>
                      {formatMessage({ id: 'pages.login.login' })}
                    </a>
                  ),
                }}
              />
            </Text>
          </>
        )}
      </Box>
    </ContentPageLayout>
  );
};

export default ActivatePage;
